import React, { useEffect, useState } from "react";
import { Container, Button, Modal, Form, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewCustormer,
  deleteCustormerData,
  fetchAllCustormers,
  updateCustormerData,
} from "../redux/actions/CustormerAction";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios"; // Import axios for making API calls

function CustomerManagement() {
  const dispatch = useDispatch();
  const { customers, loading } = useSelector((state) => state.customers);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    company_name: "",
    short_name: "",
    address: "",
    email: "",
    contact_number: "",
    registration_number: "",
    location: ""
  });
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [apiError, setApiError] = useState("");
  const [emailExists, setEmailExists] = useState(false); // State to track if email exists

  useEffect(() => {
    dispatch(fetchAllCustormers());
  }, [dispatch]);

  // Debounce function to delay API calls
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  // Function to check if email exists
  const checkEmailExists = async (email) => {
    try {
      const response = await axios.post("http://127.0.0.1:8000/api/check-email/", { email });
      setEmailExists(response.data.exists); // Assuming the API returns { exists: true/false }
    } catch (error) {
      console.error("Error checking email:", error);
    }
  };

  // Debounced version of the email check function
  const debouncedCheckEmail = debounce(checkEmailExists, 500);

  const handleShowModal = () => {
    if (selectedCustomer) {
      setFormData({
        company_name: selectedCustomer.company_name || "",
        short_name: selectedCustomer.short_name || "",
        address: selectedCustomer.address || "",
        email: selectedCustomer.email || "",
        contact_number: selectedCustomer.contact_number || "",
        registration_number: selectedCustomer.registration_number || "",
        location: selectedCustomer.location || ""
      });
    } else {
      setFormData({
        company_name: "",
        short_name: "",
        address: "",
        email: "",
        contact_number: "",
        registration_number: "",
        location: ""
      });
    }
    setErrors({});
    setApiError("");
    setEmailExists(false); // Reset email exists state
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormData({
      company_name: "",
      short_name: "",
      address: "",
      email: "",
      contact_number: "",
      registration_number: "",
      location: ""
    });
    setErrors({});
    setSelectedCustomer(null);
    setMessage("");
    setApiError("");
    setEmailExists(false); // Reset email exists state
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));

    // Check email in real-time
    if (name === "email") {
      debouncedCheckEmail(value);
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.company_name?.trim()) {
      newErrors.company_name = "Company name is required";
    }
    if (!formData.short_name?.trim()) {
      newErrors.short_name = "Short name is required";
    }
    if (!formData.address?.trim()) {
      newErrors.address = "Address is required";
    }
    if (!formData.email?.trim()) {
      newErrors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    } else if (emailExists) {
      newErrors.email = "Email is already registered";
    }
    if (!formData.contact_number?.trim()) {
      newErrors.contact_number = "Contact number is required";
    }
    if (!formData.registration_number?.trim()) {
      newErrors.registration_number = "Registration number is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      if (selectedCustomer) {
        await dispatch(updateCustormerData(selectedCustomer.id, formData));
        setMessage("Customer updated successfully!");
      } else {
        await dispatch(createNewCustormer(formData));
        setMessage("Customer added successfully!");
      }
      dispatch(fetchAllCustormers()); // Fetch updated list
      handleCloseModal(); // Close modal only on success
    } catch (error) {
      console.error("Error:", error);
      if (error.response && error.response.data) {
        setApiError(error.response.data.message || "An error occurred. Please try again.");
      } else {
        setApiError("An error occurred. Please try again.");
      }
    }
  };

  const handleDeleteRow = async () => {
    if (!selectedCustomer) {
      alert("Please select a customer to delete.");
      return;
    }
    const confirmDelete = window.confirm("Are you sure you want to delete this customer?");
    if (confirmDelete) {
      try {
        await dispatch(deleteCustormerData(selectedCustomer.id));
        dispatch(fetchAllCustormers()); // Fetch updated list
        setSelectedCustomer(null); // Reset selection
        setMessage("Customer deleted successfully!");
      } catch (error) {
        console.error("Error deleting customer:", error);
        setMessage("An error occurred while deleting the customer.");
      }
    }
  };

  return (
    <Container className="mt-4">
      <h2 className="mb-4 text-center">Manage Companies</h2>
      <Button variant="success" className="mb-3" onClick={handleShowModal}>
        Add Company
      </Button>
      <Button
        variant="primary"
        className="mb-3 mx-1"
        onClick={() =>
          selectedCustomer ? handleShowModal() : alert("Please select a row")
        }
      >
        Edit
      </Button>
      <Button variant="danger" className="mb-3" onClick={handleDeleteRow}>
        Delete
      </Button>

      {message && <Alert variant="success">{message}</Alert>}

      <div className="card">
        <DataTable
          value={customers}
          selectionMode="single"
          selection={selectedCustomer}
          onSelectionChange={(e) => setSelectedCustomer(e.value)}
          dataKey="id"
          metaKeySelection={false}
          tableStyle={{ minWidth: "50rem" }}
          loading={loading}
        >
          <Column field="company_name" header="Company Name"></Column>
          <Column field="short_name" header="Short Name"></Column>
          <Column field="registration_number" header="Company Registration Number"></Column>
          <Column field="contact_number" header="Contact Number"></Column>
          <Column field="email" header="Email"></Column>
          <Column field="address" header="Address"></Column>
        </DataTable>
      </div>

      {/* Modal for Add/Edit */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedCustomer ? "Edit Customer" : "Add Customer"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {apiError && <Alert variant="danger">{apiError}</Alert>}
          <Form onSubmit={handleFormSubmit}>
            <Form.Group controlId="company_name">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                name="company_name"
                value={formData.company_name}
                onChange={handleInputChange}
                isInvalid={!!errors.company_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.company_name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="short_name">
              <Form.Label>Short Name</Form.Label>
              <Form.Control
                type="text"
                name="short_name"
                value={formData.short_name}
                onChange={handleInputChange}
                isInvalid={!!errors.short_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.short_name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                isInvalid={!!errors.address}
              />
              <Form.Control.Feedback type="invalid">
                {errors.address}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="location">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="textbox"
                name="location"
                value={formData.location}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                isInvalid={!!errors.email || emailExists}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email || (emailExists && "Email is already registered")}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="contact_number">
              <Form.Label>Contact Number</Form.Label>
              <Form.Control
                type="text"
                name="contact_number"
                value={formData.contact_number}
                onChange={handleInputChange}
                isInvalid={!!errors.contact_number}
              />
              <Form.Control.Feedback type="invalid">
                {errors.contact_number}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="registration_number">
              <Form.Label>Registration Number</Form.Label>
              <Form.Control
                type="text"
                name="registration_number"
                value={formData.registration_number}
                onChange={handleInputChange}
                isInvalid={!!errors.registration_number}
              />
              <Form.Control.Feedback type="invalid">
                {errors.registration_number}
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              {selectedCustomer ? "Update" : "Add"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default CustomerManagement;