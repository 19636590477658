import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Table, Image, Spinner, Alert } from 'react-bootstrap';
import { FaInfoCircle, FaListAlt, FaCogs, FaBook, FaRoute, FaTools, FaPlayCircle } from 'react-icons/fa'; // React Icons
import CarouselImage from '../component/CarouselImage';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductDetailsByProductId, fetchProductForecastDetailsByProductId } from '../redux/actions/itemActions';
import { fetchItemsMaterials } from '../redux/actions/ItemMetirialActions';
import TableView from '../component/TableView';
import axios from 'axios';
import ProductRoutes from '../component/ProductRoutes';
import Resources from '../component/Resources';
import './styles/bomview.css';
import ProductUsage from '../component/ProductUsage';
import CarbonFootPrint from '../component/CarbonFootPrint';

const ProductDetailsTable = ({ details }) => {
  const allowedFields = [
    'product_version',
    'brand',
    'manufacturer',
    'certification',
    'dimensions',
    'minimum_order_quantity',
    'seller',
    'supplier_ability',
    'package_details'
  ];

  return (
    <Table striped bordered hover className="mt-3">
      <tbody>
        {allowedFields.map((key) => (
          <tr key={key}>
            <td><strong>{key.replace(/_/g, ' ').toUpperCase()}</strong></td>
            <td>{details[key] || 'No information available'}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const Bomview = () => {
  const [imageClick, setImageClick] = useState(false);
  const [clickedImage, setClickedImage] = useState(null);
  const { product_id } = useParams();
  const dispatch = useDispatch();
  const { loading: loadingDetails, itemDetails = {}, error: errorDetails } = useSelector(state => state.itemsData);
  const { loading: loadingMaterials, itemsMetirials = {}, error: errorMaterials } = useSelector(state => state.itemsMetirials);
  const { loading: loadingproductForcastDetails, productForcastDetails = {}, error: errorProductForeCastDetails } = useSelector(state => state.itemsData);
  const [tasks, setTasks] = useState([]);
  


  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/product-forecast-stages/product/${product_id}/`);
        const fetchedTasks = response.data.map((task) => ({
          ...task,
          start: new Date(task.start_date),
          end: new Date(task.end_date),
          progress: 50
        }));
        setTasks(fetchedTasks);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };
    fetchTasks();
  }, [product_id]);

  const columns = ["Material", "Description", "Quantity", "Color", "Unit", "Price"];
  const data_names = ["materials", "description", "quantity", "colors", "unit", "price"];

  useEffect(() => {
    if (product_id) {
      dispatch(fetchProductDetailsByProductId(product_id));
      dispatch(fetchItemsMaterials(product_id));

      // dispatch(fetchProductForecastDetailsByProductId(product_id))

      dispatch(fetchProductForecastDetailsByProductId(product_id));

    }
  }, [dispatch, product_id]);

  const handleImageClick = (image) => {
    setImageClick(true);
    setClickedImage(image);
  };

  const images = [
    itemDetails.image1_url,
    itemDetails.image2_url,
    itemDetails.image3_url,
    itemDetails.image4_url,
  ].filter(Boolean);

  const generateYouTubeEmbedLink = (url) => {
    const videoIdMatch = url.match(/(?:youtu\.be\/|(?:www\.|m\.)?youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=))([^&\n]{11})/);
    return videoIdMatch ? `https://www.youtube.com/embed/${videoIdMatch[1]}` : null;
  };

  if (loadingDetails || loadingMaterials || loadingproductForcastDetails) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (errorDetails) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">Error: {errorDetails}</Alert>
      </Container>
    );
  }


  console.log('item details',itemDetails);
  

  return (
    <Container className="mt-4">
      <h2 className="mb-4">
        <FaInfoCircle className="me-2" />
        {itemDetails.name || 'Product Name Unavailable'}
      </h2>

      <Row className="mb-4">
        <Col md={6}>
          <Card className="shadow">
            <Card.Body>
              {imageClick ? (
                <Image src={clickedImage} fluid className="w-100" alt="Product Image" />
              ) : (
                <CarouselImage images={images} />
              )}
              <div className="d-flex mt-3">
                {images.map((img, index) => (
                  <Image
                    key={index}
                    src={img}
                    className="img-thumbnail me-2"
                    style={{ width: '100px', cursor: 'pointer' }}
                    onClick={() => handleImageClick(img)}
                    alt={`Thumbnail ${index + 1}`}
                  />
                ))}
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="shadow">
            <Card.Body>
              <h5 className="mb-3">
                <FaListAlt className="me-2" />
                Product Review Details
              </h5>
              <p className="text-justify">{itemDetails.description || 'No description available.'}</p>
              <ProductDetailsTable details={itemDetails} />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {itemDetails.features && (
        <Row className="mb-4">
          <Col>
            <Card className="shadow">
              <Card.Body>
                <h5 className="mb-3">
                  <FaCogs className="me-2" />
                  Features
                </h5>
                <div dangerouslySetInnerHTML={{ __html: itemDetails.features }} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      <Row className="mb-4">
        <Col>
          <TableView params={itemsMetirials} columns={columns} para_names={data_names} />
        </Col>
      </Row>

      {itemDetails.instructions && (
        <Row className="mb-4">
          <Col>
            <Card className="shadow">
              <Card.Body>
                <h5 className="mb-3">
                  <FaBook className="me-2" />
                  Instructions to Use
                </h5>
                <p>{itemDetails.instructions}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      <Row className="mb-4">
        <Col>
          <Card className="shadow" >
            <Card.Body style={{ overflow: 'hidden' }}>
              <h5 className="mb-3">
                <FaRoute className="me-2" />
                Product Routes
              </h5>
              <ProductRoutes product_id={itemDetails.id} />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <Card className="shadow">
            <Card.Body>
              <h5 className="mb-3">
                <FaTools className="me-2" />
                Usage Of The Product
              </h5>
              <ProductUsage product_id={itemDetails.id} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      
      <Row className="mb-4">
        <Col>
          <Card className="shadow">
            <Card.Body>
              <h5 className="mb-3">
                <FaTools className="me-2" />
                Resources
              </h5>
              <Resources resources={itemDetails.resource_groups} />
            </Card.Body>
          </Card>
        </Col>
      </Row>  




      <Row className="mb-4">
        <Col>
          <Card className="shadow">
            <Card.Body>
              <h5 className="mb-3">
                <FaTools className="me-2" />
                Carbon Foot Print
              </h5>
              <CarbonFootPrint/>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {itemDetails.youtube_link && (
        <Row className="mb-4">
          <Col>
            <Card className="shadow">
              <Card.Body>
                <h5 className="mb-3">
                  <FaPlayCircle className="me-2" />
                  Product Video
                </h5>
                <div className="ratio ratio-16x9">
                  <iframe
                    src={generateYouTubeEmbedLink(itemDetails.youtube_link)}
                    title="YouTube video player"
                    allowFullScreen
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Bomview;
