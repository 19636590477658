import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFilterdItemsForWeb, fetchItems } from '../redux/actions/itemActions';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './productsView.css'; // Custom styles
import Loder from '../component/Loder';
import { Link, useNavigate } from 'react-router-dom';
import { FaOpencart } from "react-icons/fa";

function ProductsView() {
    const dispatch = useDispatch();
    const { items, loading, error } = useSelector((state) => state.itemsData);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchFilterdItemsForWeb());
    }, [dispatch]);

    if (loading) {
        return <div className='d-flex justify-content-center align-items-center'><Loder /></div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const navigateToBom = (product_id) => {
        navigate(`/bom/${product_id}`);
    }

    return (
        <Container className="mt-4">
            <Row>
                {items.map((item) => (
                    <Col xs={12} sm={6} md={4} lg={3} key={item.id} className="mb-4">
                        <Card className="product-card shadow" onClick={() => { navigateToBom(item.product_id) }}>
                            <Card.Img variant="top" src={item.image1_url} />
                            <Card.Body>
                                {/* <Card.Title><strong>{item.name}</strong></Card.Title> */}
                                <Card.Title>{item.name}</Card.Title>
                                <Card.Text>
                                    {item.category}<br></br>
                                    {item.model}<br />
                                    <strong>{item.brand}</strong>
                                </Card.Text>
                                <div className='d-flex align-items-center justify-content-end'>

                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default ProductsView;
