import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Chart } from 'react-google-charts';

function ProductRoutes({ product_id }) {
    const [productOperations, setProductOperations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchAllProductOperationsByProductId();
    }, [product_id]);

    const fetchAllProductOperationsByProductId = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/operations/fetch-product-operations-by-product-id/${product_id}/`
            );
            console.log('fetch-product-operations-by-product-id', response);
            setProductOperations(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching product operations:', error);
            setError('Failed to fetch product operations. Please try again later.');
            setLoading(false);
        }
    };

    const formatAllOperationDataToGrantChart = (productOperations) => {
        if (!Array.isArray(productOperations)) return [];

        const today = new Date();
        const formattedOperations = [];

        // Create a map to store operations by their ID for quick lookup
        const operationMap = new Map();
        productOperations.forEach((operation) => {
            operationMap.set(operation.id, operation);
        });

        productOperations.forEach((operation) => {
            const daysOfOperation = parseInt(operation.days_of_operation, 10);
            let startDate, endDate;

            if (operation.previous_operation) {
                // Find the previous operation
                const previousOperation = operationMap.get(operation.previous_operation);
                if (previousOperation) {
                    // Set the start date of the current operation to the end date of the previous operation
                    startDate = new Date(previousOperation.end_date || today);
                } else {
                    // If the previous operation is not found, start from today
                    startDate = new Date(today);
                }
            } else {
                // If there is no previous operation, start from today
                startDate = new Date(today);
            }

            // Calculate the end date based on the start date and days of operation
            endDate = new Date(startDate);
            endDate.setDate(startDate.getDate() + daysOfOperation);

            const formattedOperation = {
                id: operation.id,
                operation: operation.operation_name, // Use operation_name as the task name
                start_date: startDate,
                end_date: endDate,
                progress: 0, // Default progress to 0
                dependencies: operation.previous_operation ? operation.previous_operation.toString() : null, // Set dependencies based on previous_operation
            };

            formattedOperations.push(formattedOperation);

            // Update the operation in the map with its end date for future reference
            operation.end_date = endDate;
            operationMap.set(operation.id, operation);
        });

        console.log('formattedOperations', formattedOperations);
        return formattedOperations;
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    const formattedTasks = formatAllOperationDataToGrantChart(productOperations);

    // Prepare data for Google Gantt Chart
    const ganttData = [
        [
            { type: 'string', label: 'Task ID' },
            { type: 'string', label: 'Task Name' },
            { type: 'string', label: 'Resource' },
            { type: 'date', label: 'Start Date' },
            { type: 'date', label: 'End Date' },
            { type: 'number', label: 'Duration' },
            { type: 'number', label: 'Percent Complete' },
            { type: 'string', label: 'Dependencies' },
        ],
        ...formattedTasks.map((task) => [
            task.id.toString(), // Task ID
            task.operation, // Task Name
            task.operation, // Resource (set to operation for now)
            task.start_date, // Start Date
            task.end_date, // End Date
            (task.end_date - task.start_date) / (1000 * 60 * 60 * 24), // Duration in days
            task.progress, // Percent Complete
            task.dependencies, // Dependencies
        ]),
    ];

    // Gantt chart options
    const options = {
        height: 500,
        gantt: {
            criticalPathEnabled: true,
            criticalPathStyle: {
                stroke: '#e64a19',
                strokeWidth: 10,
            },
            trackHeight: 50,
            timeline: {
                showRowLabels: true,
                barHeight: 20,
                gridlineColor: '#eaeaea',
            },
        },
    };

    return (
        <div>
            <div style={{ height: '500px', overflow: 'auto' }}>
                <Chart
                    chartType="Gantt"
                    width="100%"
                    height="1000px" // Increase height to accommodate more rows
                    data={ganttData}
                    options={options}
                />
            </div>
        </div>
    );
}

export default ProductRoutes;