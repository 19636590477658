import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { FaUsers, FaChartLine, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { Chart } from 'react-google-charts';
import './styles/Resources.css';

function Resources({ resources }) {
  const [resourceGroups, setResourceGroups] = useState([]);
  const [filteredResourceGroups, setFilteredResourceGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resourceResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/resource-groups/`);
        console.log('resourceResponse', resourceResponse);
        setResourceGroups(resourceResponse.data);

        // Filter resource groups if resources prop is provided
        if (resources) {
          const filtered = resourceResponse.data.filter((rg) => resources.includes(rg.id));
          setFilteredResourceGroups(filtered);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch resource groups. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [resources]);

  // Mock chart data for each resource
  const getChartData = (resource) => {
    return [
      ['Category', 'Value'],
      ['Capacity Used', resource.total_capacity],
    ];
  };

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <div className="text-center text-danger">{error}</div>;
  }

  // Use filteredResourceGroups if resources prop is provided, otherwise use all resourceGroups
  const displayedResources = resources ? filteredResourceGroups : resourceGroups;

  return (
    <div>
      <Row>
        {displayedResources.map((resource, index) => (
          <Col key={index} sm={12} md={6} lg={4} className="mb-4">
            <Card className="gradient-card">
              <Card.Body>
                <Card.Title className="card-title">
                  <FaUsers className="icon" /> <h5>{resource.name}</h5>
                </Card.Title>
                <Card.Text>
                  <div className="card-detail">
                    <FaChartLine className="icon" />
                    <strong>Group ID:</strong> {resource.group_id}
                  </div>
                  <div className="card-detail">
                    <FaChartLine className="icon" />
                    <strong>Capacity Type:</strong> {resource.capacity_type}
                  </div>
                  <div className="card-detail">
                    <FaChartLine className="icon" />
                    <strong>Total Capacity:</strong> {resource.total_capacity}
                  </div>
                  <div className="card-detail">
                    {resource.availability ? (
                      <FaCheckCircle className="icon available" />
                    ) : (
                      <FaTimesCircle className="icon unavailable" />
                    )}
                    <strong>Availability:</strong> {resource.availability ? 'Available' : 'Unavailable'}
                  </div>
                </Card.Text>

                {/* Chart for the resource */}
                <div className="chart-container">
                  <Chart
                    chartType="BarChart"
                    width="100%"
                    height="200px"
                    data={getChartData(resource)}
                    options={{
                      title: 'Capacity Usage',
                      chartArea: { width: '70%' },
                      hAxis: {
                        title: 'Capacity',
                        minValue: 0,
                      },
                      vAxis: {
                        title: 'Category',
                      },
                      colors: ['#FF5733', '#33FF57'], // Custom colors for the bars
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default Resources;