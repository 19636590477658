
import { Card, Col, Row, Table } from 'react-bootstrap';
import { FaTree, FaChartLine, FaLeaf } from 'react-icons/fa';
import { Chart } from 'react-google-charts';
import './styles/CarbonFootPrint.css'; // Create a CSS file for custom styles
import React, { useState, useEffect } from "react";
import axios from "axios";

function CarbonFootPrint() {
  // Dummy data for carbon footprint metrics
  const carbonData = {
    totalFootprint: 1200, // in tons
    reductionTarget: 800, // in tons
    reductionProgress: 65, // in percentage
  };

  const [data, setData] = useState([]);

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "http://127.0.0.1:8000/api/carbon/carbon-footprint-data/"
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching carbon footprint data:", error);
      }
    };

    fetchData();
  }, []);

  // Dummy data for carbon footprint trends
  const chartData = [
    ['Year', 'Carbon Footprint (tons)'],
    ['2018', 1500],
    ['2019', 1400],
    ['2020', 1300],
    ['2021', 1200],
    ['2022', 1100],
    ['2023', 1000],
  ];

  return (
    <div className="carbon-footprint">
      <h2>Carbon Footprint</h2>
      <Row className="mb-4">
        {/* Total Carbon Footprint Card */}
        
        {/*<Col md={4}>
          <Card className="carbon-card">
            <Card.Body>
              <Card.Title>
                <FaTree className="icon" /> Total Carbon Footprint
              </Card.Title>
              <Card.Text>
                <h3>{carbonData.totalFootprint} tons</h3>
                <p>Total CO2 emissions this year</p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>*/}
       

      <div style={{  display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Card style={{ width: "70%", padding: "10px", backgroundColor: "#7f9b7a" }}>
        
        {/* Main Task */}
        {data.length > 0 && (
          <div style={{ marginBottom: "15px" }}>
            <label style={{ fontWeight: "bold", display: "block" }}>Main Task</label>
            <input
              type="text"
              value={data[0]?.main_task}
              readOnly
              style={{ width: "100%", padding: "5px", backgroundColor: "#ddd", border: "none" }}
            />
          </div>
        )}

        {/* Sub Task Table */}
        <Table borderless>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    value={item.sub_task}
                    readOnly
                    style={{ width: "100%", padding: "5px", backgroundColor: "#ddd", border: "none" }}
                  />
                </td>
                <td style={{ width: "80px" }}>
                  <input
                    type="text"
                    value={item.total_emissions}
                    readOnly
                    style={{ width: "100%", padding: "5px", backgroundColor: "#ddd", border: "none", textAlign: "center" }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
      </div>

        {/* Reduction Target Card */}
        {/*
        <Col md={4}>
          <Card className="carbon-card">
            <Card.Body>
              <Card.Title>
                <FaLeaf className="icon" /> Reduction Target
              </Card.Title>
              <Card.Text>
                <h3>{carbonData.reductionTarget} tons</h3>
                <p>Target for CO2 reduction by 2025</p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        */}

        {/* Reduction Progress Card */}
        {/*
        <Col md={4}>
          <Card className="carbon-card">
            <Card.Body>
              <Card.Title>
                <FaChartLine className="icon" /> Reduction Progress
              </Card.Title>
              <Card.Text>
                <h3>{carbonData.reductionProgress}%</h3>
                <p>Progress towards reduction target</p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        */}
      </Row>

      {/* Carbon Footprint Trend Chart */}
      <Card className="chart-card">
        <Card.Body>
          <Card.Title>Carbon Footprint Trends</Card.Title>
          <Chart
            chartType="LineChart"
            width="100%"
            height="400px"
            data={chartData}
            options={{
              title: 'Carbon Footprint Over Time',
              curveType: 'function',
              legend: { position: 'bottom' },
              hAxis: {
                title: 'Year',
              },
              vAxis: {
                title: 'Carbon Footprint (tons)',
              },
              colors: ['#34A853'],
            }}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default CarbonFootPrint;
