import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [auth, setAuth] = useState({
    isAuthenticated: !!localStorage.getItem('AuthToken'),
    token: localStorage.getItem('AuthToken') || null,
  });

  const login = (token) => {
    const loginTimestamp = Date.now();
    localStorage.setItem('AuthToken', token);
    localStorage.setItem('loggedDate', loginTimestamp.toString());
    setAuth({ isAuthenticated: true, token });
    navigate('/');
  };

  const logoutUser = useCallback(() => {
    [
      'AuthToken',
      'loggedUserName',
      'auth',
      'user_role',
      'user_id',
      'userCompanie',
      'selectedCompany',
      'user_companies',
      'loggedDate',
    ].forEach((key) => localStorage.removeItem(key));

    setTimeout(() => {
      window.location.reload();
      navigate('/register');
    }, 500);
  }, [navigate]);

  const checkSessionTimeout = useCallback(() => {
    const loginTime = localStorage.getItem('loggedDate');
    const expirationTime = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

    if (loginTime && Date.now() - Number(loginTime) > expirationTime) {
      logoutUser();
    }
  }, [logoutUser]);

  useEffect(() => {
    checkSessionTimeout();
    const interval = setInterval(checkSessionTimeout, 60 * 1000); // Check every minute

    return () => clearInterval(interval); // Cleanup on unmount
  }, [checkSessionTimeout]);

  return (
    <AuthContext.Provider value={{ auth, login, logoutUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
