import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';

function ProductUsage({ product_id }) {
    const [forecastData, setForecastData] = useState([]);
    const [monthlyUsageData, setMonthlyUsagedata] = useState([]);
    const [yearlyUsageData, setYearlyUsageData] = useState([]);

    useEffect(() => {
        const fetchAllForecastDataByCreatedCompany = async (productId) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/forecasts/`);

                const cid = Number(localStorage.getItem('userCompanie'));

                const filteredForecastData = response.data.filter((forecast) => {
                    return (
                        forecast.created_company === cid &&
                        forecast.forecast_product?.id === productId
                    );
                });

                setForecastData(filteredForecastData);

                // Process monthly and yearly data
                processMonthlyData(filteredForecastData);
                processYearlyData(filteredForecastData);

                console.log('Filtered Forecast Data:', filteredForecastData);
            } catch (error) {
                console.error('Error fetching forecast data:', error);
            }
        };

        fetchAllForecastDataByCreatedCompany(product_id);
    }, [product_id]);

    const processMonthlyData = (data) => {
        const monthlyData = {};

        // Initialize all months with 0
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        months.forEach((month) => {
            monthlyData[month] = 0;
        });

        // Aggregate quantities by month
        data.forEach((forecast) => {
            const month = forecast.order_month;
            if (month && monthlyData.hasOwnProperty(month)) {
                monthlyData[month] += forecast.quantity || 0;
            }
        });

        // Format data for the chart
        const formattedMonthlyData = [
            ['Month', 'Usage'],
            ...Object.entries(monthlyData).map(([month, usage]) => [month, usage]),
        ];

        setMonthlyUsagedata(formattedMonthlyData);
    };

    const processYearlyData = (data) => {
        const yearlyData = {};

        // Aggregate quantities by year
        data.forEach((forecast) => {
            const year = forecast.year;
            if (year) {
                yearlyData[year] = (yearlyData[year] || 0) + (forecast.quantity || 0);
            }
        });

        // Format data for the chart
        const formattedYearlyData = [
            ['Year', 'Usage'],
            ...Object.entries(yearlyData).map(([year, usage]) => [year, usage]),
        ];

        setYearlyUsageData(formattedYearlyData);
    };

    // Chart options
    const chartOptions = {
        title: 'Product Usage',
        chartArea: { width: '50%' },
        hAxis: {
            title: 'Usage',
            minValue: 0,
        },
        vAxis: {
            title: 'Time Period',
        },
        colors: ['#4285F4'],
    };

    return (
        <div style={styles.container}>
            <div style={styles.cardContainer}>
                {/* Monthly Usage Card */}
                <div style={styles.card}>
                    <h6>Monthly Usage</h6>
                    <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="300px"
                        data={monthlyUsageData}
                        options={chartOptions}
                    />
                </div>

                {/* Yearly Usage Card */}
                <div style={styles.card}>
                    <h6>Yearly Usage</h6>
                    <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="300px"
                        data={yearlyUsageData}
                        options={chartOptions}
                    />
                </div>
            </div>
        </div>
    );
}

// Styles
const styles = {
    container: {
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
    },
    cardContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
        justifyContent: 'center',
    },
    card: {
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '20px',
        width: '30%',
        minWidth: '300px',
        textAlign: 'center',
    },
};

export default ProductUsage;